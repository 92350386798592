import store from '@/store'
const VisitorsPage = () => import('@/views/VisitorsPage.vue')

export default [
  {
    path: '/',
    component: VisitorsPage,
    //  如果本地有userInfo，则直接进入首页
    beforeEnter: (to, from, next) => {
      const cookieUserInfo = localStorage.getItem('userInfo')
      if (cookieUserInfo) {
        const u = JSON.parse(cookieUserInfo)
        store.commit('recordInfo', {
          type: 'userInfo',
          value: u
        })
        next('/home1')
        return
      }
      next()
    }
  },
  {
    path: '/visitorsPage',
    component: VisitorsPage
  }
]
