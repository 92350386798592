<template>
  <div class="center">
    <img class="btn" @click="goLogin" src="@/assets/img/VisitorsPage_btn.png" alt="">
  </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    // 跳转到登录页
    goLogin () {
      this.$router.push('/login')
    }
  }
}
</script>

<style scoped lang='less'>
.center {
  position: relative;
  min-height: 100vh;
  background: url('~@/assets/img/VisitorsPage_bg.png') no-repeat;
  background-size: 100% 100%;
}

.btn {
  position: absolute;
  height: 100px;
  left: 50%;
  bottom: 30%;
  transform: translate(-50%, );
}
</style>
