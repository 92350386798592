<template>
  <div class="contant">
    <div class="herder_box">
      <div class="herder">
        <div class="back" @click="backPage">
          <img src="@/assets/img/contant_back.png" alt="" />
          <span>Daftar</span>
        </div>
      </div>
      <div class="search">
        <img src="@/assets/img/search.png" alt="" />
        <input type="text" v-model="searchValue" placeholder="Cari" @input="search" />
      </div>
    </div>
    <div class="list">
      <lazy-component>
        <van-cell :title="item.username || item.userphone" v-for="(item, index) in contantList" :key="index"
          @click="checkedContacts(item)" />
      </lazy-component>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { Cell, IndexBar, IndexAnchor, Toast, Lazyload } from 'vant'
import request from '@/utils/request'
import { validateContactPhone } from '@/utils/validator'

Vue.use(Cell)
Vue.use(IndexBar)
Vue.use(IndexAnchor)
Vue.use(Toast)
Vue.use(Lazyload, {
  lazyComponent: true
})
export default {
  name: 'AddressBook',
  data () {
    return {
      searchValue: '',
      contantList: [],
      allContantList: []
    }
  },
  created () {
    this.getContantList()
  },
  methods: {
    backPage () {
      this.$router.back()
    },
    getContantList () {
      request.post('contantList')
        .then(res => {
          this.contantList = res.list
          this.allContantList = res.list
        })
    },
    search () {
      if (this.searchValue) {
        this.contantList = this.contantList.filter(item => {
          return item.username.includes(this.searchValue)
        })
      } else {
        this.contantList = this.allContantList
      }
    },

    checkedContacts (item) {
      const { number, contact } = this.$route.query
      const arrList = JSON.parse(contact)
      const index = arrList.findIndex(itemObj => {
        return itemObj.contactPhone === item.userphone
      })
      if (index !== -1) {
        Toast('Kedua nomor kontak darurat tidak boleh sama')
        return
      }

      // 使用正则表达式替换空格、连字符、+62和+字符
      const cleanedStr = item.userphone.replace(/[\s+-]|\+62\b/g, '')
      item.userphone = cleanedStr.replace(/^628(.*)$/, '08$1')
      if (String(cleanedStr.charAt(0)) === '8') {
        item.userphone = '0' + cleanedStr
      }
      if (!validateContactPhone(item.userphone)) {
        Toast('Silakan pilih nomor ponsel yang dimulai dengan 08 atau +628.')
        return
      }
      this.searchValue = ''
      this.search()
      this.$router.back()
      this.$router.replace({
        path: '/basicInfo',
        query: {
          userphone: item.userphone,
          username: item.username,
          number: number
        }
      })
    }
  }
}
</script>

<style scoped lang='less'>
.contant {
  min-height: 100vh;
  background: #fff;
  position: relative;
  height: 1000px;
  padding-bottom: 50px;

  .herder_box {
    background: #fff;
    z-index: 99;
    position: fixed;
    left: 0;
    right: 0;
  }

  .herder {
    padding: 0 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 500;
    font-size: 30px;
    color: #0040dc;

    .back {
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;

      img {
        width: 36px;
        height: 36px;
      }
    }
  }

  .search {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0 20px;
    margin-bottom: 50px;

    img {
      position: absolute;
      // top: 50%;
      left: 25px;
      // transform: translateY(-50%);
      width: 50px;
    }

    input {
      padding-left: 60px;
      width: 100%;
      height: 64px;
      background: #e3e3e5;
      border-radius: 8px 8px 8px 8px;
      border: none;
      font-size: 26px;
      color: #000;
      margin: 0;

      &::placeholder {
        color: #5d5d64;
      }
    }
  }

  .list {
    padding-top: 200px;
  }
}
</style>
