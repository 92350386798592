<template>
  <div class="login">
    <BaseHeaderBar title="" :showCustomerService="false" :showBackButton="true" bgColor="#29b9d8" />
    <img class="top-img" src="@/assets/img/login_top_img.png" alt="">
    <form class="login-content" @submit.prevent="onSubmit">
      <div class="page_title">
        <span :class="item.activate ? 'activate' : ''" v-for="item in pageList" :key="item.name"
          @click="skipLogin(item.name)">{{ item.name }}</span>
      </div>
      <div class="triangle" :style="{ left: pageList.length === 2 ? '75%' : '50%' }"></div>
      <div class="input-block">
        <input v-model.trim="phone" type="tel" autofocus placeholder="Masukkan nomor handphone dengan awalan 08"
          maxlength="13" />
      </div>
      <div v-if="showPasswordInput" class="input-block">
        <input v-model.trim="password" maxlength="8" type="tel" class="password" :placeholder="tipText" />
      </div>
      <div class="input-block verify-code-block">
        <input v-model.trim="verifyCode" type="tel" maxlength="4"
          placeholder="Masukkan 4 digit angka kode verifikasi" />
        <div class="verify-code-btn" v-if="isGetCode" @click="getVerifyCode">
          Dapatkan kode verifikasi
        </div>
        <div class="verify-code-btn count-down" v-else>{{ countDown }}s</div>
      </div>
      <button type="submit" :class="['confirm-btn', showPasswordInput ? 'near' : 'far']">
        {{ buttonText }}
      </button>
      <!-- <div class="register" v-if="isIOS ? true : false" @click="showDaftar">
        Belum ada akun? Klik regis
        <van-icon name="arrow" />
      </div> -->
    </form>

    <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay class="verify-img-dialog">
      <div class="verify-body">
        <h3 class="verify-title">Diperlukan verifikasi</h3>
        <div class="verify-input-box">
          <input type="text" v-model="imgVerifyCode" placeholder="Silakan masukkan" maxlength="4" />
          <img class="yanzhengma" ref="verifyImg" />
        </div>
        <div class="refresh-btn">
          <span>Klik gambar untuk refresh</span>
          <img class="yanzhengma_shuaxin" alt="" src="@/assets/img/refresh-icon.png" @click="refreshVerifyCode" />
        </div>
        <div class="handle_btn">
          <button type="button" class="cancel-btn" @click="onCloseDialog()">
            Batal
          </button>
          <button type="button" class="verify-confirm-btn" @click="onVerify">
            Yakin
          </button>
        </div>
      </div>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Toast, Dialog, Icon } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword, validateVerifyCode } from '@/utils/validator'
import dialogVisible from '@/mixins/dialogVisible'
import { baseURL } from '@/config'
import { isIOS } from '@/utils/detect'
Vue.use(Icon)
Vue.use(Toast)
const maxTime = 150
let timer
const clearTimer = () => {
  timer && clearTimeout(timer) && (timer = null)
}
const titleMap = {
  forgetPassword: 'Lupa Password',
  messageLogin: 'Login dengan SMS',
  register: 'Daftar'
}
const buttonText = {
  forgetPassword: 'Yakin',
  messageLogin: 'Login',
  register: 'Daftar'
}
const apiMap = {
  forgetPassword: 'usdrerForgetPassword',
  messageLogin: 'usdrerLogin',
  register: 'usdrerRegistration'
}

const messageTypeMap = {
  forgetPassword: 3,
  messageLogin: 2,
  register: 1
}

export default {
  name: 'LoginForm',
  props: {
    type: {
      type: String,
      required: true
    },
    pageList: {
      type: Array
    },
    activ: {
      default: 0
    }
  },
  mixins: [dialogVisible],
  data () {
    return {
      phone: '',
      password: '',
      verifyCode: '',
      isGetCode: true,
      countDown: maxTime,
      verifyImg: '',
      activate: 1,
      imgVerifyCode: '',
      isIOS
    }
  },
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  computed: {
    title () {
      return titleMap[this.type]
    },
    buttonText () {
      return buttonText[this.type]
    },
    showPasswordInput () {
      return this.type === 'forgetPassword' || this.type === 'register'
    },
    tipText () {
      if (this.type === 'register') {
        return 'Atur 8 digit password'
      } else {
        return 'Masukkan 8 digit angka password'
      }
    }
  },
  watch: {
    visible (val) {
      if (val) {
        this.$nextTick(() => {
          this.getImgVerifyCode()
          this.$refs.input0[0].focus()
        })
      }
    }
  },
  methods: {
    // 跳转登录页
    skipLogin (name) {
      if (name === 'Masuk via kata sandi') {
        this.$router.push({
          path: '/login'
        })
      }
    },
    validatePhoneNumber () {
      const regex = /^(08|628)/
      if (!regex.test(this.phone)) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      return true
    },
    validate () {
      if (!this.validatePhoneNumber()) return false
      if (this.showPasswordInput) {
        if (!this.password) {
          Toast(this.tipText)
          return false
        }
        if (!validatePassword(this.password)) {
          Toast('Masukkan 8 digit angka password')
          return false
        }
      }
      if (!this.verifyCode) {
        Toast('Masukkan 4 digit angka kode verifikasi')
        return false
      }
      if (!validateVerifyCode(this.verifyCode)) {
        Toast('Kode verifikasi salah')
        return false
      }
      return true
    },
    startCountDown () {
      if (this.countDown <= 0) {
        this.reset()
      } else {
        timer = setTimeout(() => {
          this.countDown--
          this.startCountDown()
        }, 1000)
      }
    },
    getVerifyCode () {
      if (!this.validatePhoneNumber()) return
      if (this.type === 'register' || this.type === 'forgetPassword') {
        if (!validatePassword(this.password)) {
          Toast('Masukkan 8 digit angka password')
          return false
        }
      }
      const postData = {
        usdrerPhone: this.phone,
        tydrpe: messageTypeMap[this.type],
        smdrsType: 1
      }
      this.imgVerifyCode && (postData.imdrgCode = this.imgVerifyCode)
      request.post('usdrersendMessages', postData)
        .then(() => {
          Toast('Kode verifikasi terkirim. Silakan cek')
          this.countDown = maxTime
          this.isGetCode = false
          this.startCountDown()
        })
        .catch(err => {
          if (err.code === 504) {
            this.visible = true
          }
          this.reset()
        })
    },
    getImgVerifyCode () {
      this.$refs.verifyImg.src = `${baseURL}usdrergetGraphic?usdrerPhone=${this.phone}&t=${+Date.now()}`
    },
    reset () {
      clearTimer()
      this.isGetCode = true
      this.countDown = maxTime
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        usdrerPhone: this.phone,
        smdrs_Verificationcode: this.verifyCode,
        smdrsType: 1
      }
      if (this.type === 'forgetPassword') {
        postData.usdrerPassword = this.password
      } else if (this.type === 'messageLogin') {
        postData.lodrginType = 2
      } else if (this.type === 'register') {
        const ixabisoelinye = sessionStorage.getItem('ixabisoelinye')
        if (ixabisoelinye) {
          postData.ixabisoelinye = ixabisoelinye
        }
        postData.apitype = sessionStorage.getItem('apitype') || '1'
        postData.usdrerPassword = this.password
        postData.prdromotionChannel = 'AppStore'
      }
      request.post(apiMap[this.type], postData)
        .then(res => {
          if (this.type === 'forgetPassword') {
            const { query } = this.$route
            this.$router.push({
              path: '/login',
              query
            })
          } else if (this.type === 'register' || this.type === 'messageLogin') {
            this.$store.commit('recordInfo', {
              type: 'userInfo',
              value: res
            })
            localStorage.setItem('userInfo', JSON.stringify(res))
            this.addressBook()
            this.$router.push('/home1')
          }
        })
        .finally(() => {
          this.flag = false
        })
    },
    onVerify () {
      const postData = {
        usdrerPhone: this.phone,
        grdraphicCode: this.imgVerifyCode
      }
      request.post('usdrercheckGraphic', postData)
        .then(res => {
          Toast('Verifikasi Berhasil')
          this.visible = false
        }).catch(() => {
          this.getImgVerifyCode()
        })
    },
    refreshVerifyCode () {
      this.getImgVerifyCode()
    },
    // 注册
    showDaftar () {
      const { query } = this.$route
      this.$router.push({
        path: '/register',
        query
      })
    }

  },
  deactivated () {
    this.reset()
  }
}
</script>

<style scoped lang="less">
.login {
  background: #fff;
  min-height: 100vh;

  .top-img {
    width: 100%;
    height: 330px;
    margin-bottom: 50px;
  }
}

.login-content {
  position: relative;
  padding: 40px;

  .page_title {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    text-align: center;

    span {
      flex: 1;
      font-size: 34px;
      font-weight: 400;
      color: #444;
    }

    .activate {
      font-weight: bold;
      color: #000;
    }
  }
}

.input-block {
  display: flex;
  align-items: center;

  &:nth-child(n + 2) {
    margin-top: 50px;
  }

  input {
    display: block;
    width: 100%;
    line-height: 88px;
    padding-left: 20px;
    border: none;
    border: solid 1px #ccc;
    box-sizing: border-box;
    font-size: 30px;
    font-weight: 600;
    color: #000;
    background: #f0fcff;

    &::placeholder {
      font-size: 24px;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.password {
  -webkit-text-security: disc;
}

.verify-code-block {
  display: flex;

  input {
    flex: 1;
  }
}

.verify-code-btn {
  position: absolute;
  right: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  width: 160px;
  height: 85px;
  text-align: center;
  font-size: 24px;
  font-weight: 500;
  color: #fff;
  background: #2bb9d7;
  border-radius: 8px;
}

.count-down {
  padding-top: 0;
  line-height: 88px;
}

.confirm-btn {
  .submit-btn;
  margin-top: 132px;
  width: 100%;
  border-radius: 55px;
  font-weight: 500;
  font-size: 30px;
}

.verify-img-dialog {
  width: 663px;
  height: 657px;
}

.verify-title {
  padding-top: 64px;
  text-align: center;
  font-size: 40px;
  font-weight: 800;
  color: #ffffff;
  text-shadow: 0px 5px 5px #dd470f;
}

.verify-body {
  width: 663px;
  height: 657px;
}

.verify-img-block {
  display: flex;
  align-items: center;
  margin-top: 74px;
  height: 144px;
}

.verify-img {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  border: 2px solid @theme-color;
  border-right: none;
  border-radius: 8px 0 0 8px;
  box-sizing: border-box;

  img {
    height: 100%;
  }
}

.refresh-btn {
  display: flex;
  justify-content: end;
  align-items: center;

  span {
    font-size: 24px;
    font-weight: 500;
    color: #ffffff;
    margin-right: 20px;
  }

  img {
    width: 30px;
    height: 30px;
    margin-right: 30px;
  }
}

.verify-input-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0 40px;
  margin-bottom: 50px;
  margin-top: 130px;

  input {
    height: 94px;
    width: 100%;
    line-height: 94px;
    font-size: 32px;
    border: none;
    padding-left: 30px;
    background: none;
    border-bottom: 2px solid rgba(225, 225, 225, 0.2);
    font-weight: 500;
    color: #fff;

    &::placeholder {
      color: #ffffff;
      font-weight: 500;
      font-size: 30px;
      opacity: 0.8;
    }
  }

  .yanzhengma {
    width: 251px;
    height: 94px;
    position: absolute;
    right: 0;
    bottom: 20px;
  }

  .yanzhengma_shuaxin {
    position: absolute;
    right: -10px;
    top: -90px;
    width: 50px;
    height: 44px;
  }
}

.handle_btn {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 20px;
  padding: 0 32px;
  margin-top: 80px;

  .cancel-btn {
    .submit-btn;
  }

  .verify-confirm-btn {
    .submit-btn;
  }
}

.register {
  font-size: 30px;
  font-weight: 500;
  color: #43c1db;
  line-height: 24px;
  text-align: center;
  margin-top: 41px;
}
</style>
