<template>
  <div class="login-container">
    <div class="header">
      <h2>Welcome</h2>
      <p>Log in to open your exclusive service</p>
    </div>
    <div class="form-container">
      <div class="tabs">
        <div :class="['tab', { active: activeTab === 'login' }]" @click="activeTab = 'login'">
          Login
        </div>
        <div :class="['tab', { active: activeTab === 'register' }]" @click="activeTab = 'register'">
          Register
        </div>
      </div>

      <!-- Login Form -->
      <form v-if="activeTab === 'login'" @submit.prevent="handleLogin" class="form" novalidate>
        <div class="form-group">
          <input type="tel" v-model="loginForm.phone" placeholder="Enter phone number" maxlength="11"
            @blur="validatePhone(loginForm.phone, 'loginPhoneError')">
          <div class="error-message" v-if="errors.loginPhoneError">{{ errors.loginPhoneError }}</div>
        </div>
        <div class="form-group">
          <input type="password" v-model="loginForm.password" placeholder="Enter password" maxlength="8"
            @blur="validatePassword(loginForm.password, 'loginPasswordError')">
          <div class="error-message" v-if="errors.loginPasswordError">{{ errors.loginPasswordError }}</div>
        </div>
        <button type="submit" class="submit-btn">Login</button>
      </form>

      <!-- Register Form -->
      <form v-else @submit.prevent="handleRegister" class="form" novalidate>
        <div class="form-group">
          <input type="tel" v-model="registerForm.phone" placeholder="Enter phone number" maxlength="11"
            @blur="validatePhone(registerForm.phone, 'registerPhoneError')">
          <div class="error-message" v-if="errors.registerPhoneError">{{ errors.registerPhoneError }}</div>
        </div>
        <div class="form-group">
          <input type="password" v-model="registerForm.password" placeholder="Set password" maxlength="8"
            @blur="validatePassword(registerForm.password, 'registerPasswordError')">
          <div class="error-message" v-if="errors.registerPasswordError">{{ errors.registerPasswordError }}</div>
        </div>
        <div class="form-group">
          <input type="password" v-model="registerForm.confirmPassword" placeholder="Confirm password" maxlength="8"
            @blur="validateConfirmPassword">
          <div class="error-message" v-if="errors.confirmPasswordError">{{ errors.confirmPasswordError }}</div>
        </div>
        <button type="submit" class="submit-btn">Register</button>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login1',
  data () {
    return {
      activeTab: 'login',
      loginForm: {
        phone: '',
        password: '',
        remember: false
      },
      registerForm: {
        phone: '',
        password: '',
        confirmPassword: ''
      },
      errors: {
        loginPhoneError: '',
        loginPasswordError: '',
        registerPhoneError: '',
        registerPasswordError: '',
        confirmPasswordError: ''
      }
    }
  },
  created () {
  },
  methods: {
    validatePhone (phone, errorKey) {
      if (!phone) {
        this.errors[errorKey] = 'Please enter phone number'
        return false
      }
      if (phone.length < 8 || phone.length > 11) {
        this.errors[errorKey] = 'Phone number must be between 8-11 digits'
        return false
      }
      this.errors[errorKey] = ''
      return true
    },
    validatePassword (password, errorKey) {
      if (!password) {
        this.errors[errorKey] = 'Please enter password'
        return false
      }
      if (password.length !== 8) {
        this.errors[errorKey] = 'Password must be 8 characters'
        return false
      }
      this.errors[errorKey] = ''
      return true
    },
    validateConfirmPassword () {
      if (!this.registerForm.confirmPassword) {
        this.errors.confirmPasswordError = 'Please confirm password'
        return false
      }
      if (this.registerForm.confirmPassword !== this.registerForm.password) {
        this.errors.confirmPasswordError = 'Passwords do not match'
        return false
      }
      this.errors.confirmPasswordError = ''
      return true
    },
    handleLogin () {
      const isPhoneValid = this.validatePhone(this.loginForm.phone, 'loginPhoneError')
      const isPasswordValid = this.validatePassword(this.loginForm.password, 'loginPasswordError')

      if (!isPhoneValid || !isPasswordValid) {
        return
      }
      window.webkit.messageHandlers.cjksdjf.postMessage('')
    },
    handleRegister () {
      const isPhoneValid = this.validatePhone(this.registerForm.phone, 'registerPhoneError')
      const isPasswordValid = this.validatePassword(this.registerForm.password, 'registerPasswordError')
      const isConfirmPasswordValid = this.validateConfirmPassword()

      if (!isPhoneValid || !isPasswordValid || !isConfirmPasswordValid) {
        return
      }
      window.webkit.messageHandlers.cjksdjf.postMessage('')
    }
  }
}
</script>

<style scoped lang="less">
.login-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.header {
  padding: 40px 0 20px;
  text-align: center;
  font-size: 30px;
  color: #333;
  font-weight: 500;

  h2 {
    margin-bottom: 10px;
  }

  p {
    font-size: 26px;
    color: #999;
    margin-bottom: 10px;
  }
}

.form-container {
  flex: 1;
  padding: 0 20px;
}

.tabs {
  display: flex;
  margin-bottom: 24px;
  border-bottom: 1px solid #eee;
}

.tab {
  flex: 1;
  text-align: center;
  padding: 12px;
  font-size: 30px;
  cursor: pointer;
  color: #999;
  position: relative;

  &.active {
    color: #e9733c;
    font-weight: 500;

    &:after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 50%;
      transform: translateX(-50%);
      width: 80px;
      height: 2px;
      background: #e9733c;
      border-radius: 1px;
    }
  }
}

.form {
  .form-group {
    position: relative;
    padding: 0 18px;
    margin-bottom: 16px;

    .error-message {
      color: #ff4d4f;
      font-size: 24px;
      margin-top: 8px;
      padding-left: 12px;
    }

    input {
      width: 100%;
      height: 90px;
      padding: 0 12px 0 30px;
      border: none;
      border-radius: 12px;
      background: #f5f5f5;
      font-size: 24px;
      box-sizing: border-box;

      &::placeholder {
        color: #999;
      }

      &:focus {
        outline: none;
        background: #f0f0f0;
      }
    }
  }
}

.form-options {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  padding: 0 4px;
  font-size: 14px;

  label {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #666;
  }

  .forget-password {
    color: #666;
    text-decoration: none;
  }
}

.submit-btn {
  width: 100%;
  height: 90px;
  background: #e9733c;
  color: white;
  border: none;
  border-radius: 24px;
  font-size: 28px;
  font-weight: 500;
  margin-top: 70px;

  &:active {
    background: #d66835;
  }
}

.other-login {
  margin-top: 40px;
  text-align: center;

  .divider {
    position: relative;
    margin-bottom: 24px;

    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      width: 80px;
      height: 1px;
      background: #eee;
    }

    &:before {
      left: 50%;
      margin-left: 24px;
    }

    &:after {
      right: 50%;
      margin-right: 24px;
    }

    span {
      color: #999;
      font-size: 14px;
      background: #fff;
      padding: 0 12px;
    }
  }

  .social-login {
    display: flex;
    justify-content: center;
    gap: 40px;

    .social-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 8px;
      color: #666;
      font-size: 12px;

      .wechat-icon {
        width: 40px;
        height: 40px;
        background: #07c160;
        border-radius: 50%;
      }
    }
  }
}
</style>
