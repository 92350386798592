<template>
  <div class="login">
    <BaseHeaderBar title="" bgColor="#29b9d8" :showCustomerService="false" :showBackButton="false"
      :showCustomerDaftar="isIOS ? true : false" />
    <img class="top-img" src="@/assets/img/login_top_img.png" alt="">
    <form class="login-body" @submit.prevent="onSubmit">
      <div class="title-block">
        <div class="password-login activate">Masuk via kata sandi</div>
        <div class="message-login" @click="goMessageLogin">
          Masuk dengan SMS
        </div>
      </div>
      <div class="input-block">
        <input v-model.trim="phone" type="tel" autofocus placeholder="Masukkan nomor handphone dengan awalan 08"
          maxlength="13" />
      </div>
      <div class="input-block">
        <input v-model.trim="password" maxlength="8" type="tel" class="password"
          placeholder="Masukkan 8 digit angka password" />
      </div>
      <div class="handle">
        <span class="forget-password" @click="forgetPassword">Lupa Password？</span>
      </div>
      <button type="button" class="login-btn" @click="onSubmit">
        Login Password
      </button>
      <div class="register" v-if="isIOS ? true : false" @click="showDaftar">
        Belum ada akun? Klik regis
        <van-icon name="arrow" />
      </div>
    </form>
  </div>
</template>

<script>
import { Toast, Icon } from 'vant'
import request from '@/utils/request'
import { validatePhoneNumber, validatePassword } from '@/utils/validator'
import { isIOS } from '@/utils/detect'
import Vue from 'vue'
Vue.use(Icon)
export default {
  name: 'Login',
  data () {
    return {
      phone: '',
      password: '',
      isIOS
    }
  },
  methods: {
    forgetPassword () {
      const { query } = this.$route
      this.$router.push({
        path: '/forgetPassword',
        query
      })
    },
    validate () {
      const regex = /^(08|628)/
      if (!regex.test(this.phone)) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!this.phone) {
        Toast('Masukkan nomor handphone dengan awalan 08')
        return false
      }
      if (!validatePhoneNumber(this.phone)) {
        Toast('Nomor handphone salah')
        return false
      }
      if (!this.password) {
        Toast('Masukkan 8 digit angka password')
        return false
      }
      if (!validatePassword(this.password)) {
        Toast('Password salah')
        return false
      }
      return true
    },
    onSubmit () {
      if (!this.validate()) return
      if (this.flag) return
      this.flag = true
      const postData = {
        lodrginType: 1,
        usdrerPhone: this.phone,
        usdrerPassword: this.password
      }
      request.post('usdrerLogin', postData)
        .then(res => {
          this.$store.commit('recordInfo', {
            type: 'userInfo',
            value: res
          })
          localStorage.setItem('userInfo', JSON.stringify(res))
          this.addressBook()
          this.$router.push('/home1')
        })
        .finally(() => {
          this.flag = false
        })
    },
    goMessageLogin () {
      const { query } = this.$route
      this.$router.push({
        path: '/messageLogin',
        query
      })
    },
    // 注册
    showDaftar () {
      const { query } = this.$route
      this.$router.push({
        path: '/register',
        query
      })
    }

  }
}
</script>

<style scoped lang="less">
.login {
  display: flex;
  flex-direction: column;
  // justify-content: end;
  background: #fff;
  min-height: 100vh;

  .top-img {
    width: 100%;
    height: 330px;
    margin-bottom: 50px;
  }
}

.login-body {
  position: relative;
  padding: 40px;

}

.header-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 73px 30px 0;

  p {
    font-weight: 600;

    &:first-child {
      font-size: 40px;
      color: #4837bc;
    }

    &:last-child {
      font-size: 32px;
      color: #f9f9f9;
    }
  }
}

.title-block {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;

  &>div {
    flex: 1;
    text-align: center;
    font-size: 34px;
    font-weight: 400;
    color: #444;
  }

  .activate {
    font-weight: bold;
    color: #000;
  }
}

.input-block {
  display: flex;
  align-items: center;

  &:nth-child(n + 2) {
    margin-top: 50px;
  }

  img {
    margin-left: 41px;
  }

  input {
    display: block;
    width: 100%;
    line-height: 88px;
    padding-left: 20px;
    border: none;
    border: solid 1px #ccc;
    box-sizing: border-box;
    font-size: 30px;
    font-weight: 600;
    color: #000;
    background: #f0fcff;

    &::placeholder {
      font-size: 26px;
    }

    &:-webkit-autofill {
      transition: background-color 5000s ease-in-out 0s;
    }
  }
}

.password {
  -webkit-text-security: disc;
}

.handle {
  display: flex;
  justify-content: end;
  margin-top: 30px;
  color: #27b8d7;

  span {
    font-size: 32px;
    font-weight: 500;
    margin-bottom: 100px;
  }
}

.login-btn {
  .submit-btn;
  margin-top: 29px;
  border-radius: 55px;
  font-weight: 500;
  font-size: 30px;
}

.register {
  font-size: 30px;
  font-weight: 500;
  color: #43c1db;
  line-height: 24px;
  text-align: center;
  margin-top: 41px;
}
</style>
